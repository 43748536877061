import React from "react";
import MainBanner from "../components/MainBanner";
import { Row, Col } from "react-bootstrap";
import goldImg from "../assets/images/gold.png"
import bronzeImg from "../assets/images/bronz.png"
import silverImg from "../assets/images/silver.png"

const PrizeMoney = () => {
  return (
    <div className="main-wrapper prize-money-conatiner">
      <section>
        <MainBanner />
      </section>
      <section className="container my-4">
        <h1 className="title-main text-center pt-4">Prize Money - 2024</h1>
        <div className="refund-section py-4 px-xs-1">
            <h2 className="heading-1">Structure</h2>
            <ul>
              <li>
                <p>
                  <span>
                    The race and age categories apply to men and women
                    participants and are subject to race rules and regulations.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Race Category - Overall Fastest Men | Overall Fastest Women
                    both in 10K &amp; 5K.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Age Category - 18 years to 22 years | 23 years to 49 years |
                    50+ years both men and women in 10K and 5K.
                  </span>
                </p>
              </li>
            </ul>
            <br></br>
            <h1 className="head-1 table-head-1"><b><span>MEN</span></b></h1>
            <Row className="px-3">
                <Col xs={12} md={12} className="m-auto">
                    <div align="left">
                        <table width="100%" className="table-prize-men">
                            <tbody>
                                <tr className="table-top" >
                                    <td colSpan=""></td>
                                    <td width="20%"></td>
                                    <td align="center" ><img src={goldImg} alt="gold prize"/></td>
                                    <td  align="center" ><img src={silverImg} alt="silver prize"/></td>
                                    <td  align="center" ><img src={bronzeImg} alt="bronze prize"/></td>
                                </tr>
                                <tr>
                                    <td rowspan="2" className="td-bg-color">
                                        <p><span>Fastest Runner</span></p>
                                    </td>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>10000</span></p>
                                    </td>
                                    <td>
                                        <p><span>7000</span></p>
                                    </td>
                                    <td>
                                        <p><span>5000</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>7000</span></p>
                                    </td>
                                    <td>
                                        <p><span>5000</span></p>
                                    </td>
                                    <td>
                                        <p><span>3000</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5"></td>
                                </tr>
                                <tr>
                                    <td rowspan="2" className="td-bg-color">
                                        <p><span>18 to 22 years</span></p>
                                    </td>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>3500</span></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1000</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5"></td>
                                </tr>
                                <tr>
                                    <td rowspan="2" className="td-bg-color">
                                        <p><span>23 to 49 years</span></p>
                                    </td>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>3500</span></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1000</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5"></td>
                                </tr>
                                <tr>
                                    <td rowspan="2" className="td-bg-color">
                                        <p><span>Above 50 years</span></p>
                                    </td>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>3500</span></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                    </td>
                                    <td>
                                        <p><span>2500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1500</span></p>
                                    </td>
                                    <td>
                                        <p><span>1000</span></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <br></br>
            <h1 className="head-1"><b><span>WOMEN</span></b></h1>
            <Row className="px-3">
                <Col xs={12} md={12} className="m-auto">
                    <table border={1} width="100%" className="table-prize-women">
                        <tbody>
                            <tr>
                                <td rowspan="2" className="td-bg-color">
                                    <p><span>Fastest Runner</span></p>
                                </td>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>10000</span></p>
                                </td>
                                <td>
                                    <p><span>7000</span></p>
                                </td>
                                <td>
                                    <p><span>5000</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>7000</span></p>
                                </td>
                                <td>
                                    <p><span>5000</span></p>
                                </td>
                                <td>
                                    <p><span>3000</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5"></td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="td-bg-color">
                                    <p><span>18 to 22 years</span></p>
                                </td>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>3500</span></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                                <td>
                                    <p><span>1000</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5"></td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="td-bg-color">
                                    <p><span>23 to 49 years</span></p>
                                </td>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>3500</span></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                                <td>
                                    <p><span>1000</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5"></td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="td-bg-color">
                                    <p><span>Above 50 years</span></p>
                                </td>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>10K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>3500</span></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><strong><i class="fa fa-trophy" aria-hidden="true"></i><span>5K</span></strong></p>
                                </td>
                                <td>
                                    <p><span>2500</span></p>
                                </td>
                                <td>
                                    <p><span>1500</span></p>
                                </td>
                                <td>
                                    <p><span>1000</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <h2 className="heading-1 mt-4">Rules</h2>
              <ul>
                <li>
                  <p>
                    <span>
                      Prize money will be distributed to winners based on their
                      official finishing times and placements in their
                      respective race categories.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    <b>Finisher Medal Cut-off Time: to be eligible for the finisher medal, participants in all categories must complete the race by 8:30 am.</b>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    Prize Qualification Cut-off Times: to qualify for prizes, participants in the 5K category must finish within 40 minutes, while those in the 10K category must finish within 60 minutes.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    Race Start Time Window: participants must commence the race within 20 minutes of their designated start time to be considered for prize eligibility.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                    10K Race Start Guidelines: for the 10K race starting at 6:00 am, participants must begin no later than 6:20 am to remain eligible for prizes.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                    5K Race Start Guidelines: participants in the 5K race, commencing at 6:15 am, should initiate the race by 6:35 am at the latest to maintain eligibility for prizes.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Participants who are disqualified for rule violations
                      during or after the race may forfeit their eligibility for
                      prize money.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                      To qualify for prizes, participants must ensure that their
                      Bib tag timing is successfully captured at all designated
                      timing points along the course, including both the start
                      and finish mats. Incomplete or missing timing data at any
                      point may result in disqualification from prize
                      eligibility.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Participants achieving the title of the overall fastest
                      runner will not be eligible for additional age category
                      prizes. In the event of a participant securing both the
                      fastest overall time and an age category win, only the
                      highest-value prize will be awarded to ensure fair
                      distribution and equal opportunities across prize
                      categories.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The 2K run will not be timed or be eligible for prize
                      money.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The race director/organizer&rsquo;s decision is final.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      All prize winners will be evaluated based on chip time.
                    </span>
                  </p>
                </li>
              </ul>
        </div>
      </section>
    </div>
  );
};

export default PrizeMoney;