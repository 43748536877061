import React, { useEffect, useState } from "react";
import MainBanner from "../components/MainBanner";
import { Row, Col, Form, Button } from "react-bootstrap";
import goldImg from "../assets/images/gold.png"
import bronzeImg from "../assets/images/bronz.png"
import silverImg from "../assets/images/silver.png"
import axios from "axios";

const Results = () => {
    const [filteredWinnersList, setFilteredWinnersList] = useState([]);
    const [place, setPlace] = useState('bengaluru');
    const [raceList, setRaceList] = useState([]);
    const [genders, setGenders] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedRace, setSelectedRace] = useState("overall");
    const [selectedGender, setSelectedGender] = useState("overall");
    const [selectedCategory, setSelectedCategory] = useState("overall");

    useEffect(() => {
        handleSearch();
    }, [])

    const handleSearch = () => {
        axios
            .get(`https://www.itwgo.in/xlri/pages/winner/${place}/`)
            .then(res => {
                if (res.status) {
                    let result1 = res.data;
                    let fastest_result = result1.filter(x => x.category === "Fastest Men" || x.category === "Fastest Women");
                    //setFilteredWinnersList(fastest_result);
                    let uniqueCategory = [...new Set(result1.map(item => item?.category))]?.filter(x => x !== undefined)
                    let uniqueRace = [...new Set(result1.map(item => item.race))]?.filter(x => x !== undefined)
                    let uniqueGender = [...new Set(result1.map(item => item.gender))]?.filter(x => x !== undefined)
                    setRaceList(uniqueRace);
                    setGenders(uniqueGender);
                    setCategoryList(uniqueCategory);
                    let result = [];
                    if (selectedRace !== "overall") {
                        let data = result1?.filter(x => x.location.toLowerCase() === place)?.filter(x => x.race === selectedRace);
                        result = data;
                    }
                    if (selectedGender !== "overall" && result.length === 0) {
                        let data = result1?.filter(x => x.location.toLowerCase() === place)?.filter(x => x.gender === selectedGender);
                        result = data;
                    }
                    if (selectedGender !== "overall" && result.length !== 0) {
                        let data = result?.filter(x => x.location.toLowerCase() === place)?.filter(x => x.gender === selectedGender);
                        result = data;
                    }
                    if (selectedCategory !== "overall" && result.length === 0) {
                        let data = result1?.filter(x => x.location.toLowerCase() === place)?.filter(x => x.category === selectedCategory);
                        result = data;
                    }
                    if (selectedCategory !== "overall" && result.length !== 0) {
                        let data = result?.filter(x => x.location.toLowerCase() === place)?.filter(x => x.category === selectedCategory);
                        result = data;
                    }
                    if (selectedRace === "overall" && selectedGender === "overall" && selectedCategory === "overall") {
                        let data = fastest_result?.filter(x => x.location.toLowerCase() === place);
                        result = data;
                    }
                    setFilteredWinnersList(result)
                }
            })
            .catch(error => console.log(error));

    }

    return (
        <div className="main-wrapper prize-money-conatiner">
            <section>
                <MainBanner />
            </section>
            <section className="container my-4">
                <h1 className="title-main text-center pt-4">Winners</h1>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="result-section py-3 px-xs-1">
                            <h1 className="title-main text-center">2024</h1>
                            <Row className="result-section-top p-3 mb-3">
                                <Col xs={12} md={3}>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={place}
                                        onChange={(e) => setPlace(e.target.value)}
                                    >
                                        <option value={"bengaluru"}>Bengaluru</option>
                                        <option value={"chennai"}>Chennai</option>
                                        <option value={"mumbai"}>Mumbai</option>
                                        <option value={"kolkata"}>Kolkata</option>
                                        <option value={"jamshedpur"}>Jamshedpur</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Select
                                        aria-label="Default select example"
                                        // value={value}
                                        onChange={(e) => setSelectedRace(e.target.value)}
                                    >
                                        <option value={"overall"}>Overall</option>
                                        {raceList?.map((x, i) =>
                                            <option value={x} key={i}>{x} Race</option>
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Select
                                        aria-label="Default select example"
                                        // value={value}
                                        onChange={(e) => setSelectedGender(e.target.value)}
                                    >
                                        <option value={"overall"}>Overall</option>
                                        {genders?.map((x, i) =>
                                            <option value={x} key={i}>{x === "M" ? "Men" : "Women"}</option>
                                        )}

                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Select
                                        aria-label="Default select example"
                                        // value={value}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    >
                                        <option value={"overall"}>Overall</option>
                                        {categoryList?.map((x, i) =>
                                            <option value={x} key={i}>{x}</option>
                                        )}

                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={2} className="my-auto">
                                    <Button className="btn btn-primary btn-confirm" onClick={() => handleSearch()}>Submit</Button>
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col xs={12} md={12} className="m-auto">
                                    <div align="left">
                                        <table width="100%" className="table-data">
                                            <thead>
                                                <tr>
                                                    <th>PLACE</th>
                                                    <th>BIB</th>
                                                    <th>NAME</th>
                                                    <th>TIME</th>
                                                    <th>GENDER</th>
                                                    <th>RACE</th>
                                                    <th>CATEGORY</th>
                                                    <th>DISTANCE (KMS)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredWinnersList?.map((x, i) =>
                                                    <tr className="table-top" key={i}>
                                                        <td colSpan="">
                                                            {x?.position !== undefined ? (
                                                                <img src={x.position === 1 ? goldImg : x.position === 2 ? silverImg : bronzeImg}
                                                                    alt={x.position === 1 ? "gold prize" : x.position === 2 ? "silver prize" : "bronze prize"} />
                                                            ) : (<div>{""}</div>)}
                                                        </td>
                                                        <td colSpan="">{x.bib}</td>
                                                        <td colSpan="">{x.name} </td>
                                                        <td colSpan="">{x.time}</td>
                                                        <td colSpan="">{x.gender}</td>
                                                        <td colSpan="">{x.race} Race</td>
                                                        <td colSpan="">{x.category}</td>
                                                        <td colSpan="">{x.distance}</td>
                                                    </tr>
                                                )}
                                                {/* <tr className="table-top" >
                                            <td colSpan=""><img src={silverImg} alt="silver prize"/> </td>
                                            <td colSpan="">10561</td>
                                            <td colSpan="">SANTOSH</td>
                                            <td colSpan="">0:31:57</td>
                                        </tr>
                                        <tr className="table-top" >
                                            <td colSpan=""><img src={bronzeImg} alt="bronze prize"/> </td>
                                            <td colSpan="">10125</td>
                                            <td colSpan="">MANIKANTA P</td>
                                            <td colSpan="">0:33:26</td>
                                        </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </section>
        </div>
    );
};

export default Results;