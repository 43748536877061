import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import map from '../assets/images/icons/location.png'
import range from '../assets/images/icons/range.png'
import { Link } from "react-router-dom";

const UpcomingEvents = (props) => {

    useEffect(() => {
        const currentURL = window.location.href;
        var url = currentURL.split("#")[1]
        let element = document.getElementById('fixtures_section');
        if (url === "team_section") {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <Col xs={12} md={6} className="mb-4">
            <Row>
                <Col xs={12} md={6}>
                    <div className="evt-details-left">
                        <img src={props.upcomingEvents.image} />
                    </div>
                </Col>
                <Col xs={12} md={6} className="m-auto">
                    <div className="evt-details-right">
                        <h1>{props.upcomingEvents.title}</h1>
                        <p><i className="fa fa-calendar" />&nbsp; {props.upcomingEvents.start_date}</p>
                        {/* <p>{props.upcomingEvents.description}</p> */}
                        <h4><i className="fa fa-map-marker" />&nbsp; {props.upcomingEvents.location}</h4>
                        <Link to={'/register-' + props.upcomingEvents.location.toLowerCase()}><Button className="btn btn-primary">Register</Button></Link>
                    </div>
                </Col>
            </Row>
        </Col>
    )
};

export default UpcomingEvents;