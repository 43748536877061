import React from "react";
import { Button } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import banner0 from '../assets/images/banner/00.png'
import banner1 from '../assets/images/banner/01.png'
import banner2 from '../assets/images/banner/02.png'
import banner3 from '../assets/images/banner/03.png'
import banner4 from '../assets/images/banner/04.png'
// import banner5 from '../assets/images/banner/5.png'
// import banner6 from '../assets/images/banner/6.png'
// import banner7 from '../assets/images/banner/7.png'
// import banner8 from '../assets/images/banner/8.png'

const MainBanner = () => {
    const imageData = [
        {
            alt: "banner",
            url: banner0
        },
        {
            alt: "banner1",
            url: banner1
        },
        {
            alt: "banner2",
            url: banner2
        },
        {
            alt: "banner3",
            url: banner3
        },
        {
            alt: "banner4",
            url: banner4
        }        
      ];
      

return(
    <div className="main-banner bg-violet">
        <Carousel fade>
        {imageData.map((image, index) => (
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={image.url}
                alt="first slide"
                />
                {/* <Carousel.Caption>
                    { index === 0 ? (
                        <Button className="btn-pink btn-pink-banner">Book Tickets</Button>
                    ) : null }
                </Carousel.Caption> */}
            </Carousel.Item>
        ))}
        </Carousel>
    </div>
)
};

export default MainBanner;