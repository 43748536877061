import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Dropdown,
  SplitButton,
  InputGroup,
  Alert,
  Accordion,
} from "react-bootstrap";
import idcard from "../assets/images/icons/id-card.svg";
import location from "../assets/images/icons/location.svg";
import range from "../assets/images/icons/range.svg";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  LineLayer,
  Marker,
} from "react-map-gl";
import { FeatureCollection } from "geojson";
import banner3 from "../assets/images/banner/3.png";
import registrationClosed from "../assets/images/registration-closed.png";
import twoKmap from "../assets/images/2Kmap.png";
import fiveKmap from "../assets/images/5Kmap.png";
import twoKmap_CCU from "../assets/images/2K_Kolkata.png";
import fiveKmap_CCU from "../assets/images/5K_Kolkata.png";

const latlng = [
  { lat: 12.98473477593205, long: 77.60172894501792 },
  { lat: 12.984234247623744, long: 77.59704177781691 },
  { lat: 12.975912816814352, long: 77.5871216773709 },
  { lat: 12.967465966898988, long: 77.58750692399018 },
  { lat: 12.966840262903053, long: 77.59530816803023 },
  { lat: 12.984766058917838, long: 77.60172894501792 },
];

const cityData = [
  {
   "name": "Bengaluru Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-bangalore-2024"
  },
  {
   "name": "Jamshedpur Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-jamshedpur-2024"
  },
  {
   "name": "Kolkata Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-kolkata-2024"
  },
  {
   "name": "Mumbai Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-mumbai-2024"
  },
  {
   "name": "Hyderabad Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-hyderabad-2024"
  },
  {
   "name": "Chennai Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xlrunathon-chennai-2024"
  },
  {
   "name": "Pune Run",
   "results_link": "https:\/\/app.racetime.in\/results\/list?race=centrum-xl-runathon-pune-2024"
  },
  {
   "name": "Delhi Run",
   "results_link": "#"
  }
 ]

let geojson;

const layerStyle: LineLayer = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 10,
    "circle-color": "#000000",
  },
};

const Register = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [count, setCount] = useState({
    countOne: 1,
    countTwo: 0,
    countThree: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageDetails, setpageDetails] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [lng2, setLng2] = useState(-122.45);
  const [lat2, setLat2] = useState(37.78);
  const [zoom, setZoom] = useState(9);
  const [coords, setCoords] = useState([]);
  const [embedURL, setEmbedURL] = useState("");
  const [runLocation, setRunLocation] = useState("");

  const onClickShow = () => {
    setShowAdd(!showAdd);
  };
  const onClickPlus = (cnt) => {
    if (cnt === 1) {
      setCount({ countOne: 1, countTwo: 0, countThree: 0 });
    } else if (cnt === 2) {
      setCount({ countOne: 0, countTwo: 1, countThree: 0 });
    } else {
      setCount({ countOne: 0, countTwo: 0, countThree: 1 });
    }
    // setCount(count+1)
  };

  const onClickMinus = (cnt) => {
    if (cnt === 1) {
      setCount({ ...count, countOne: 0 });
    } else if (cnt === 2) {
      setCount({ ...count, countTwo: 0 });
    } else {
      setCount({ ...count, countThree: 0 });
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    var url = currentURL.split("#")[1];
    let element = document.getElementById("fixtures_section");
    if (url === "team_section") {
      element.scrollIntoView({ behavior: "smooth" });
    }
    getRoutes();
    getLocationDetails();
    getCountryList();
  }, []);

  const getLocationDetails = () => {
    setLoading(true);
    const currentURL = window.location.href;
    // var location = currentURL.split("-")[currentURL.split("-").length - 1]
    const location1 = currentURL.substring(currentURL.lastIndexOf("/") + 1);
    const city = location1.split("?")[0];
    var location = city.split("-")[city.split("-").length - 1];

    setRunLocation(runLocation);
    axios
      .get("https://www.itwgo.in/xlri/pages/details/" + location + "/")
      .then((res) => {
        if (res.status) {
          setpageDetails(res.data);
          setLoading(false);
          setEmbedURL(res.data?.embed_url);
        }
      })
      .catch((error) => setLoading(false));
  };

  const getRoutes = async () => {
    let access_token =
      "pk.eyJ1IjoiZHVyZ2EtaXR3Z28iLCJhIjoiY2xveWUwaDZyMDNsYzJrcDhuOHl1bTN0dSJ9.rZCILnu72PeZJOrkOHAg8w";
    // console.log(`https://api.mapbox.com/directions/v5/mapbox/driving/${latlng.map(x=> x.lat + "," +x.long).join(";")}?steps=true&geometries=geojson&`)
    await axios
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${latlng
          .map((x) => x.long + "," + x.lat)
          .join(
            ";"
          )}?steps=true&geometries=geojson&access_token=${access_token}`
      )
      .then((res) => {
        let new_data: FeatureCollection = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                stroke: "#f06292",
                "stroke-width": 15,
                "stroke-opacity": 1,
                "line-join": "round",
                "line-cap": "round",
              },
              geometry: {
                type: "LineString",
                coordinates: res.data.routes[0]?.geometry.coordinates,
              },
            },
          ],
        };
        geojson = new_data;
        let coordinates = res.data.routes[0]?.geometry.coordinates;
        setCoords(coordinates);
      });
  };
  // console.log(coords)
  const getCountryList = () => {
    setLoading(true);
    axios
      .get("https://itwgo.in/crew/index.php/data/country_details")
      .then((res) => {
        if (res.status) {
          setCountryList(res.data.data.data);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  };

  return (
    <div className="main-wrapper">
      <section>
        <div className="main-banner bg-violet">
          <img
            className="d-block w-100"
            src={pageDetails.banner}
            alt="first slide"
          />
        </div>
      </section>
      <section className="container">
        <div className="info-section pt-3 pb-4">
          <div className="info-section-inner mt-4">
            <div>
              <p>
                <img className="img1" src={idcard} /> {pageDetails.name}
              </p>
            </div>
            <div>
              <p>
                <img src={range} /> {pageDetails.date}
              </p>
            </div>
            <div>
              {pageDetails.map_link !== "#" ? (
                <a href={pageDetails.map_link} target="_blank">
                  <p>
                    <img src={location} className="info-section-img-3" />{" "}
                    {pageDetails.location}
                  </p>
                </a>
              ) : (
                <p>
                  <img src={location} className="info-section-img-3" />{" "}
                  {pageDetails.location}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container">
        {cityData.map(item => (
          <>
          {pageDetails.name == item.name  && (
            //&& pageDetails.name !== "Delhi Run"
          <div className="alert-box mb-3 text-center">
            <div className="alert-box mb-3">
              <Alert key="success" variant="success">
                <li>
                  Race results for {item.name} announced.{" "}
                  <a
                    href={item.results_link} 
                    target="_blank"
                  >
                    Click here
                  </a>
                </li>
              </Alert>
            </div>

            <img src={registrationClosed} style={{'width': "30%"}}/>
            {pageDetails.name == "Bengaluru Run" && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>2K Run Map</Accordion.Header>
                <Accordion.Body>
                  <img src={twoKmap} className="map-img" />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>5K & 10K Run Map</Accordion.Header>
                <Accordion.Body>
                  <img src={fiveKmap} className="map-img" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            )}
            {pageDetails.name == "Kolkata Run" && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>2K Run Map</Accordion.Header>
                <Accordion.Body>
                  <img src={twoKmap_CCU} className="map-img" />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>5K & 10K Run Map</Accordion.Header>
                <Accordion.Body>
                  <img src={fiveKmap_CCU} className="map-img" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            )}
          </div>
          )}
          </>
        ))}
        {pageDetails.name == "Delhi Run" && (
          <div>
            <div className="alert-box mb-3">
              <Alert key="danger" variant="danger">
                <li>
                  Due to unforeseen circumstances, we regret to inform you that the Delhi Run event is cancelled. We regret the inconvenience caused. 
                </li>
              </Alert>
            </div>
          </div>
        )}
      </div> */}

      <div className="container">
       {/* <iframe src={embedURL} height={650} width="100%" /> */}
       <Alert key="warning" variant="warning">
                <li>
                  Registrations opening soon.
                </li>
              </Alert>
      </div>

      <section className="container register-column my-4">
        {pageDetails.name == "Delhi Run" ? (
          null
        )
        :
        <div className="row">
          <div className="col-12 col-md-3">
            <h5>
              <b>Run Timings: </b>
            </h5>
            <div className="refund-section px-xs-1">
              <ul>
                <li>
                  {" "}
                  <p className="text-start">
                    10K: {pageDetails.run_10k_timings}
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-start">5K: {pageDetails.run_5k_timings}</p>
                </li>
                <li>
                  {" "}
                  <p className="text-start">2K: {pageDetails.run_2k_timings}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4  border-left">
            <h5>
              <b>Registration Fee Includes:</b>
            </h5>
            <div className="refund-section px-xs-1 ">
              <ul className="p-0">
                <li className="d-flex">
                  <div>
                    <ul className="inner-list">
                      <li>
                        {" "}
                        <p className="text-start">T-Shirt</p>
                      </li>
                      <li>
                        {" "}
                        <p className="text-start">Medal</p>
                      </li>
                      <li>
                        {" "}
                        <p className="text-start">E-Certificate</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="inner-list">
                      <li>
                        {" "}
                        <p className="text-start">Photo tagging</p>
                      </li>
                      <li>
                        {" "}
                        <p className="text-start">Breakfast Box</p>
                      </li>
                      <li>
                        {" "}
                        <p className="text-start">Goodie Bag</p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md-5  border-left">
            <h5>
              <b>BIB Distribution Details:</b>
            </h5>
            <div className="refund-section px-xs-1">
              <ul>
                <li>
                  {" "}
                  <p className="text-start">Date: {pageDetails.bib_date}</p>
                </li>
                <li>
                  {" "}
                  <p className="text-start">
                    Distribution Timings: {pageDetails.bib_time}
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-start">
                    Venue:{" "}
                    <a href={pageDetails.bib_glocation} target="_blank">
                      {pageDetails.bib_venue}
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        }
        
      </section>
      <section className="container my-4">
        <h1 className="title-main text-center">REFUND POLICY</h1>
        <div className="refund-section py-4 px-xs-1">
          <p className="text-start">
            We appreciate your interest in participating in our runathon event.
            Please be aware that we have a strict no refund policy in place.
            Once you register for the event and complete your payment, your
            entry is considered final and non-refundable. This policy is in
            effect regardless of the circumstances, including but not limited to
            injury, scheduling conflicts, or personal reasons that prevent your
            participation. We commit substantial resources to planning and
            organizing the event, and your registration fees directly support
            these efforts. In the event of unforeseen circumstances, such as
            event cancellation due to extreme weather or other factors beyond
            our control, we will make every effort to reschedule the event or
            offer alternative options for registered participants. Your
            understanding and support in this matter are greatly appreciated, as
            they help us ensure the success and sustainability of our runathon
            event. If you have any questions or concerns, please feel free to
            contact our event organizers for further information or
            clarifications. Thank you for your cooperation.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Register;
