import { useState } from 'react';
import { Col, Form, Row, Button, Dropdown, SplitButton, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.png'
import Modal from 'react-bootstrap/Modal';
import './footer.css'

const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();

  return (
    <>
        <footer className="bg-design-black">
            <div className="container">
                <Row>
                    <Col xs={12} md={2} className='footer-col-4'>
                        <div className="">
                            <p className="pt-2 mb-0">Trademarks And Privacy</p>
                            <Link to="/termsandconditions" className="text-white text-decoration-none"><p className="pt-2 mb-0">Terms & Conditions</p></Link>
                        </div>
                        <div className="pt-3 pb-3">
                            <img className="footer-logo" src={logo}/>
                        </div>
                    </Col>

                    <Col xs={12} md={3}>
                        <div className="">
                            <ul className="footer-ul social-links pt-3">
                                <a href="https://www.facebook.com/XLrunathon" target="_blank"><li><i className="fa fa-facebook"></i></li></a>
                                <a href="https://www.instagram.com/xlrunathon/"  target="_blank"><li><i className="fa fa-instagram"></i></li></a>
                                <a href="https://www.linkedin.com/school/xlri/?originalSubdomain=in" target="_blank"><li><i className="fa fa-linkedin"></i></li></a>
                            </ul>
                        </div>
                    </Col>
                   
                    <Col xs={12} md={5} className="footer-col-4 d-flex">
                        <div className="pb-4">
                            <h5 
                            // onClick={handleShow} 
                            className='cursor-pointer'>Jamshedpur</h5>
                            <p className="mb-0">XLRI</p>
                            <p className="mb-0">C. H. Area (East)</p>
                            <p className="mb-0">Jamshedpur - 831001</p>
                            <p className="mb-0">Jharkhand, India</p>
                        </div>
                        <div className="pb-4">
                            <h5 
                            className='cursor-pointer'>Delhi</h5>
                            <p className="mb-0">XLRI</p>
                            <p className="mb-0">NCR Campus Aurangpur Village</p>
                            <p className="mb-0">Dadri Toye, Untloda - 124103</p>
                            <p className="mb-0">Haryana, India</p>
                        </div>
                    </Col>
                    <Col xs={12} md={2} className='footer-col-4'>
                        <div className="pb-3">
                            <h5 className='cursor-pointer'>Contact us</h5>
                            <a href="https://wa.me/919611330534" className='text-white text-decoration-none' target='_blank'><p className="mb-0"><i className='fa fa-whatsapp'></i> WhatsApp</p></a>
                            <p className="mb-0 pt-2"><i className='fa fa-envelope'></i> xlrunathon@gmail.com</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="footer-col-bottom">
                <div className="container">
                    <h5>{`© ${currentYear} XLRI Runathon. All Rights Reserved.`}</h5>
                </div>
            </div>
        </footer>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title className='title-main'>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form className="participant-details pt-0">
                <Row className='px-4'>
                    <Col xs={12} md={12}>
                        <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                        />
                    </Col>
                    
                    <Col xs={12} md={12} className="mt-3">
                        <Form.Label>Email Id <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="email"
                            placeholder=""
                        />
                    </Col>

                    <Col xs={12} md={12} className="mt-3">
                        <Form.Label>Contact Number  <span className="text-danger">*</span></Form.Label>

                        <InputGroup >
                            <Form.Select
                                className="country-code"
                                aria-label="Default select example"
                                // value={participantDetails.countryCode}
                                // onChange={(e) => setParticipantDetails({ ...participantDetails, countryCode: e.target.value })}
                            >
                                <option value={"+91"}>India (+91)</option>
                                {/* {props?.countryList?.map((item, index) =>
                                    <option key={item?.origin} value={item?.country_code}>{item?.name + " (" + item?.country_code + ")"}</option>
                                )} */}
                            </Form.Select>
                            <Form.Control
                                type="text"
                                className="form-phone"
                                aria-label="Text input with dropdown button"
                            />
                        </InputGroup>
                        {/* {errors.phone !== null && (<Form.Text style={{ color: "red" }}>{errors.phone}</Form.Text>)} */}
                    </Col>

                    <Col xs={12} md={12} className="mt-3">
                        <Form.Label>Message <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder=""
                        />
                    </Col>
                    
                    <Col xs={12} md={12} className="text-center">
                        <Button className="btn btn-primary btn-confirm">Submit</Button>
                    </Col>
                </Row>
            </Form>
            </Modal.Body>
        </Modal>
    </>
  )
};

export default Footer;